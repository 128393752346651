import { render, staticRenderFns } from "./VisualDeskPlannerReportCard.vue?vue&type=template&id=de7b528c&"
import script from "./VisualDeskPlannerReportCard.vue?vue&type=script&lang=js&"
export * from "./VisualDeskPlannerReportCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VIcon,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de7b528c')) {
      api.createRecord('de7b528c', component.options)
    } else {
      api.reload('de7b528c', component.options)
    }
    module.hot.accept("./VisualDeskPlannerReportCard.vue?vue&type=template&id=de7b528c&", function () {
      api.rerender('de7b528c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/report/cards/VisualDeskPlannerReportCard.vue"
export default component.exports