var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-3" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mdAndDown ? 12 : 6 } },
            [
              _c("visual-desk-planner", {
                ref: "deskPlanner",
                attrs: {
                  value: _vm.results.deskItems,
                  brightnessValue: _vm.results.brightnesslevel,
                  workArea: _vm.results.workarea,
                  setupScore: _vm.setupScore,
                  report: "",
                  assessmentId: _vm.assessmentId,
                  reloadOnUpdate: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mdAndDown ? 12 : 6 } },
            [
              _c("p", { staticClass: "mt-4" }, [
                _c("span", { staticClass: "font-weight-medium body-1 mr-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.setupRating ===
                          _vm.$t(
                            "deskAssessment.setup.sims.rating.needsImprovement"
                          )
                          ? _vm.$t("visualDeskPlannerReportCard.title.noRating")
                          : _vm.$t("visualDeskPlannerReportCard.title.rating")
                      )
                  )
                ]),
                _c(
                  "span",
                  { class: "headline " + _vm.setupRatingColor + "--text" },
                  [_vm._v(_vm._s(_vm.setupRating))]
                )
              ]),
              _vm.computerOnDesk
                ? _c(
                    "div",
                    [
                      _vm.positiveTextItems.length > 0 && !_vm.showVeryGoodText
                        ? _c("p", { staticClass: "body-1 mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualDeskPlannerReportCard.headers.strengths"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "v-list",
                        [
                          _vm.showVeryGoodText
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: require("@/assets/images/emojis/starStruck.png"),
                                          height: "30",
                                          contain: "",
                                          eager: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-wrap mb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "visualDeskPlannerReportCard.text.veryGood"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.showVeryGoodText
                            ? _c(
                                "div",
                                _vm._l(_vm.positiveTextItems, function(text) {
                                  return _c(
                                    "v-list-item",
                                    { key: text },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "green" } },
                                            [_vm._v("mdi-check-circle")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-content", [
                                        _c("span", { staticClass: "body-1" }, [
                                          _vm._v(_vm._s(text))
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showVeryGoodText
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "body-1 mb-0" }, [
                        _vm.noComputerOrAnyPositiveItems &&
                        !_vm.setupRating.includes("Good")
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "visualDeskPlannerReportCard.text.noPositives"
                                  )
                                )
                              )
                            ])
                          : _vm.negativeTextItems.length > 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "visualDeskPlannerReportCard.headers.negatives"
                                  )
                                )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "v-list",
                        _vm._l(_vm.negativeTextItems, function(text) {
                          return _c(
                            "v-list-item",
                            { key: text },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v("mdi-close-circle")
                                  ])
                                ],
                                1
                              ),
                              _c("v-list-item-content", [
                                _c("span", { staticClass: "body-1" }, [
                                  _vm._v(_vm._s(text))
                                ])
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", { staticClass: "body-1 mb-0" }, [
                _vm.noComputerOrAnyPositiveItems &&
                !_vm.setupRating.includes("Good")
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "visualDeskPlannerReportCard.text.badSetupNoPositives"
                          )
                        )
                      )
                    ])
                  : _vm.noComputerOrAnyPositiveItems &&
                    _vm.setupRating.includes("Good")
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("visualDeskPlannerReportCard.text.noComputer")
                        )
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "visualDeskPlannerReportCard.text.recommendationSection"
                          )
                        )
                      )
                    ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }