<template>
  <v-card class="px-3">
    <v-row align="center">
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <visual-desk-planner
          ref="deskPlanner"
          :value="results.deskItems"
          :brightnessValue="results.brightnesslevel"
          :workArea="results.workarea"
          :setupScore="setupScore"
          report
          :assessmentId="assessmentId"
          reloadOnUpdate
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <p class="mt-4">
          <span class="font-weight-medium body-1 mr-3">
            {{
              setupRating ===
              $t("deskAssessment.setup.sims.rating.needsImprovement")
                ? $t("visualDeskPlannerReportCard.title.noRating")
                : $t("visualDeskPlannerReportCard.title.rating")
            }}</span
          >
          <span :class="'headline ' + setupRatingColor + '--text'">{{
            setupRating
          }}</span>
        </p>
        <div v-if="computerOnDesk">
          <p
            v-if="positiveTextItems.length > 0 && !showVeryGoodText"
            class="body-1 mb-0"
          >
            {{ $t("visualDeskPlannerReportCard.headers.strengths") }}
          </p>
          <v-list>
            <v-list-item v-if="showVeryGoodText">
              <v-list-item-avatar>
                <v-img
                  src="@/assets/images/emojis/starStruck.png"
                  height="30"
                  contain
                  eager
                />
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <p class="text-wrap mb-0">
                  {{ $t("visualDeskPlannerReportCard.text.veryGood") }}
                </p>
              </v-list-item-content>
            </v-list-item>
            <div v-if="!showVeryGoodText">
              <v-list-item v-for="text in positiveTextItems" :key="text">
                <v-list-item-avatar>
                  <v-icon color="green">mdi-check-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <span class="body-1">{{ text }}</span>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </div>
        <div v-if="!showVeryGoodText">
          <p class="body-1 mb-0">
            <span
              v-if="
                noComputerOrAnyPositiveItems && !setupRating.includes('Good')
              "
              >{{ $t("visualDeskPlannerReportCard.text.noPositives") }}</span
            >
            <span v-else-if="negativeTextItems.length > 0">{{
              $t("visualDeskPlannerReportCard.headers.negatives")
            }}</span>
          </p>
          <v-list>
            <v-list-item v-for="text in negativeTextItems" :key="text">
              <v-list-item-avatar>
                <v-icon color="red">mdi-close-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <span class="body-1">{{ text }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <p class="body-1 mb-0">
          <span
            v-if="noComputerOrAnyPositiveItems && !setupRating.includes('Good')"
            >{{
              $t("visualDeskPlannerReportCard.text.badSetupNoPositives")
            }}</span
          >
          <span
            v-else-if="
              noComputerOrAnyPositiveItems && setupRating.includes('Good')
            "
            >{{ $t("visualDeskPlannerReportCard.text.noComputer") }}</span
          >
          <span v-else>{{
            $t("visualDeskPlannerReportCard.text.recommendationSection")
          }}</span>
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VisualDeskPlanner from "@/components/deskassessment/VisualDeskPlanner";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import DeskAssessmentText from "@/services/deskassessment/desk-assessment-text.js";

export default {
  name: "VisualDeskPlannerReportCard",
  components: {
    VisualDeskPlanner
  },
  props: {
    results: Object,
    assessmentId: String
  },
  data() {
    return {
      setupRating: "-",
      setupScore: 0,
      positiveTextItems: [],
      negativeTextItems: []
    };
  },
  watch: {
    results: {
      handler() {
        this.setupCard();
      }
    },
    deep: true
  },
  mounted() {
    this.setupCard();
  },
  methods: {
    setupCard() {
      let rating = DeskAssessmentResults.getVisualDeskPlannerRating(
        this.results,
        this.$refs.deskPlanner.height
      );
      this.setupScore = Math.round(rating.score);
      this.setupRating = rating.rating;
      this.positiveTextItems =
        DeskAssessmentText.getVisualDeskPlannerPositiveText(this.results, 500);
      this.negativeTextItems =
        DeskAssessmentText.getVisualDeskPlannerNegativeText(this.results);
    }
  },
  computed: {
    setupRatingColor() {
      return DeskAssessmentResults.getVisualDeskPlannerRatingColor(
        this.setupRating
      );
    },
    showVeryGoodText() {
      return (
        this.setupRating ===
          this.$t("deskAssessment.setup.sims.rating.veryGood") &&
        this.computerOnDesk &&
        !this.beanBagMainSeating
      );
    },
    computerOnDesk() {
      return (
        !!this.results.deskItems.find(x => x.id === "laptop") ||
        !!this.results.deskItems.find(x => x.id === "laptopWithStand") ||
        !!this.results.deskItems.find(x => x.id === "computerScreen")
      );
    },
    beanBagMainSeating() {
      return DeskAssessmentResults.isMainSeatingOption(
        this.results.deskItems,
        "beanBag",
        this.results.workarea
      );
    },
    noComputerOrAnyPositiveItems() {
      return !this.computerOnDesk || this.positiveTextItems.length === 0;
    }
  }
};
</script>
