<template>
  <div>
    <v-row>
      <v-col cols="12">
        <visual-desk-planner-report-card
          v-if="results.deskItems && results.deskItems.length > 0"
          :results="results"
        >
        </visual-desk-planner-report-card>
      </v-col>
      <v-col lg="5" cols="12" v-if="workingFromHome">
        <InformationCard
          :id="workingFromHomeCard.type"
          class="fill-height"
          :sideHeader="false"
          :color="
            workingFromHomeCard.isGood
              ? $vuetify.theme.currentTheme.success
              : $vuetify.theme.currentTheme.warning
          "
          :title="workingFromHomeCard.title"
          :imageUrl="workingFromHomeCard.imageUrl"
          :text="workingFromHomeCard.text"
          :verticalAlignStart="false"
        />
      </v-col>
      <v-col :lg="workingFromHome ? 7 : 12" cols="12" class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col :lg="workingFromHome ? 12 : 6" cols="12">
            <InformationCard
              :id="hoursCard.type"
              class="fill-height"
              :sideHeader="true"
              :title="hoursCard.title"
              :color="
                hoursCard.isGood
                  ? $vuetify.theme.currentTheme.success
                  : $vuetify.theme.currentTheme.warning
              "
              :imageUrl="hoursCard.imageUrl"
              :text="hoursCard.text"
            />
          </v-col>
          <v-col :lg="workingFromHome ? 12 : 6" cols="12">
            <InformationCard
              :id="breaksCard.type"
              class="fill-height"
              :sideHeader="true"
              :title="breaksCard.title"
              :color="
                breaksCard.isGood
                  ? $vuetify.theme.currentTheme.success
                  : $vuetify.theme.currentTheme.warning
              "
              :imageUrl="breaksCard.imageUrl"
              :text="breaksCard.text"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        v-for="(card, cardIndex) in setupContent"
        :key="cardIndex"
        cols="12"
        :lg="getLargeColumnSizeForSetupContent(cardIndex)"
        class="d-flex"
      >
        <InformationCard
          :id="card.type"
          :sideHeader="cardIndex % 2 === 0"
          :title="card.title"
          :color="
            card.isGood
              ? $vuetify.theme.currentTheme.success
              : $vuetify.theme.currentTheme.warning
          "
          :imageUrl="card.imageUrl"
          :text="card.text"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InformationCard from "@/components/common/report/cards/InformationReportCard";
import ReportResults from "@/assets/json/DeskAssessment/DeskAssessmentReportResults.json";
import VisualDeskPlannerReportCard from "@/components/common/report/cards/VisualDeskPlannerReportCard.vue";
import DeskAssessmentsResultsUtil from "@/services/deskassessment/desk-assessment-results-util.js";

export default {
  name: "WorkSetupContent",
  components: {
    InformationCard,
    VisualDeskPlannerReportCard
  },
  props: {
    results: Object
  },
  data() {
    return {
      setupContent: []
    };
  },
  computed: {
    ...mapGetters(["metricSystem"]),
    workingFromHome() {
      return this.results.workingfromhome === 1;
    },
    hasBeanBag() {
      return DeskAssessmentsResultsUtil.isMainSeatingOption(
        this.results.deskItems,
        "beanBag",
        this.results.workarea
      );
    },
    hasGymBall() {
      return DeskAssessmentsResultsUtil.isMainSeatingOption(
        this.results.deskItems,
        "gymBall",
        this.results.workarea
      );
    },
    hasSpecialSeating() {
      return this.hasBeanBag || this.hasGymBall;
    },
    seatingImage() {
      if (this.hasBeanBag) {
        return "sims/v1/beanBag.svg";
      } else if (this.hasGymBall) {
        return "sims/v1/gymBall.svg";
      }
      return "emojis/chair.png";
    },
    workingFromHomeCard() {
      return {
        type: "workFromHomeCard",
        title: this.$t(
          "deskAssessment.results.workSetupContent.workingFromHomeTitle"
        ),
        imageUrl: "emojis/programmer.png",
        isGood: true,
        text: this.getCategoryText("workingfromhome", this.results)
      };
    },
    hoursCard() {
      return {
        type: "hoursCard",
        title: this.$t("deskAssessment.results.workSetupContent.hoursTitle"),
        imageUrl: "emojis/clock.png",
        isGood: this.results.hours <= 8,
        text: this.getCategoryText("hours", this.results)
      };
    },
    breaksCard() {
      return {
        type: "breaksCard",
        title: this.$t("deskAssessment.results.workSetupContent.breaksTitle"),
        imageUrl: "emojis/breaks.png",
        isGood:
          this.results.breaks === 1 &&
          this.results.breakActivity === 1 &&
          (this.results.lunch === 1 || this.results.lunch === 3),
        text: this.generateBreaksText(this.results)
      };
    },
    standingDeskCard() {
      return {
        type: "standingDeskCard",
        title: this.$t(
          "deskAssessment.results.workSetupContent.standingDeskTitle"
        ),
        imageUrl: "emojis/standingPerson.png",
        isGood: this.isStandingTimeGood() == "ok",
        text: this.generateStandingDeskText()
      };
    },
    chairCard() {
      return {
        type: "chairCard",
        title: this.$t("deskAssessment.results.workSetupContent.chairTitle"),
        imageUrl: this.seatingImage,
        isGood:
          this.results.seatadjustable === 1 &&
          this.results.feetsupported === 1 &&
          this.results.kneeangle === 1 &&
          this.results.backsupport === 1 &&
          this.results.armrests === 1 &&
          this.results.armrestsproximity === 1 &&
          !this.hasSpecialSeating,
        text: this.generateChairText()
      };
    },
    keyboardCard() {
      return {
        type: "keyboardCard",
        title: this.$t("deskAssessment.results.workSetupContent.keyboardTitle"),
        imageUrl: "emojis/keyboard.png",
        isGood: false,
        text: this.getCategoryText("keyboard", this.results)
      };
    },
    laptopCard() {
      return {
        type: "laptopCard",
        title: this.$t("deskAssessment.results.workSetupContent.laptopTitle"),
        imageUrl: "emojis/laptop.png",
        isGood: false,
        text: this.generateLaptopText()
      };
    },
    strainCard() {
      return {
        type: "strainCard",
        title: this.$t("deskAssessment.results.workSetupContent.strainTitle"),
        imageUrl: "emojis/strongArm.png",
        isGood: true,
        text: this.generateStrainText()
      };
    },
    temperatureCard() {
      return {
        type: "temperatureCard",
        title: this.$t(
          "deskAssessment.results.workSetupContent.temperatureTitle"
        ),
        imageUrl: "emojis/temperature.png",
        isGood: false,
        text: this.generateTemperatureText()
      };
    },
    noiseCard() {
      return {
        type: "noiseCard",
        title: this.$t("deskAssessment.results.workSetupContent.noiseTitle"),
        imageUrl: "emojis/microphone.png",
        isGood: this.results.noise < 3,
        text: this.getCategoryText("noise", this.results)
      };
    },
    deskHeightCard() {
      return {
        type: "deskHeightCard",
        title: this.$t(
          "deskAssessment.results.workSetupContent.deskHeightTitle"
        ),
        imageUrl: "emojis/table.svg",
        isGood: false,
        text: [
          this.$t(
            ReportResults["setup"].categories["deskHeight"][0].values.tooLow
          )
        ]
      };
    },
    noStandingDeskCard() {
      return {
        type: "noStandingDeskCard",
        title: this.$t(
          "deskAssessmentReportDetailCards.setup.noStandingDesk.title"
        ),
        imageUrl: "emojis/standingPerson.png",
        isGood: false,
        text: [
          this.$t("deskAssessmentReportDetailCards.setup.noStandingDesk.text")
        ]
      };
    },
    strainMonitorOneMonitorAndScreen() {
      if (this.results.deskItems) {
        return (
          this.results.deskItems.filter(i => i.id == "laptop").length > 0 &&
          this.results.deskItems.filter(i => i.id == "computerScreen").length ==
            1
        );
      }
      return false;
    },
    strainMultipleComputerScreens() {
      if (this.results.deskItems) {
        return (
          this.results.deskItems.filter(i => i.id == "computerScreen").length >
          1
        );
      }
      return false;
    }
  },
  mounted() {
    this.addCategoryCards();
  },
  watch: {
    results: {
      deep: true,
      handler() {
        this.addCategoryCards();
      }
    }
  },
  methods: {
    addCategoryCards() {
      this.setupContent = [];
      // seating
      if (
        this.results.seatadjustable === 2 ||
        this.results.feetsupported === 2 ||
        this.results.kneeangle === 2 ||
        this.results.backsupport === 2 ||
        (this.results.armrests === 1 && this.results.armrestsproximity === 2) ||
        this.hasSpecialSeating
      ) {
        this.setupContent.push(this.chairCard);
      }

      // keyboard
      if (
        this.results.relaxedarms === 2 ||
        this.results.wristspace === 2 ||
        this.results.easytoreach === 2
      ) {
        this.setupContent.push(this.keyboardCard);
      }

      // standingdesk
      if (this.results.standingdesk) {
        if (this.results.standingdesk === 1) {
          this.setupContent.push(this.standingDeskCard);
        }
      }

      // laptop
      if (
        this.results.laptop === 1 &&
        (this.results.dockingstation === 2 ||
          this.results.separatemousekeyboard === 2)
      ) {
        this.setupContent.push(this.laptopCard);
      }

      // strain
      if (
        (this.results.penpaper === 1 && this.results.slopedsurface === 2) ||
        (this.results.phonecalls === 1 && this.results.headset === 2) ||
        this.strainMonitorOneMonitorAndScreen ||
        this.strainMultipleComputerScreens
      ) {
        this.setupContent.push(this.strainCard);
      }

      // temperature
      if (
        this.results.temperature !== 0 &&
        this.results.temperature !== undefined
      ) {
        this.setupContent.push(this.temperatureCard);
      }

      // noise
      if (this.results.noise !== undefined) {
        this.setupContent.push(this.noiseCard);
      }

      if (
        this.results.deskHeight &&
        this.results.deskHeight === 0.33 &&
        (this.results.workarea === 1 || this.results.workarea === undefined)
      ) {
        this.setupContent.push(this.deskHeightCard);
      }

      if (
        this.results.standingdesk === 2 &&
        (this.results.breaks === 2 || this.results.breakActivity === 2)
      ) {
        this.setupContent.push(this.noStandingDeskCard);
      }

      if (this.setupContent.length > 1) {
        this.sortSetupArray();
      }
    },
    getCategoryText(category, setupResults) {
      let stringsToReturn = [];
      let i;
      for (
        i = 0;
        i < ReportResults["setup"]["categories"][category].length;
        i++
      ) {
        let responseName = ReportResults["setup"].categories[category][i].name;

        if (responseName === "suffix") {
          stringsToReturn.push(
            this.$t(ReportResults["setup"].categories[category][i].text)
          );
          continue;
        }

        let associatedResult = setupResults[responseName];
        if (associatedResult != undefined) {
          let textToAdd = this.$t(
            ReportResults["setup"].categories[category][i].values[
              associatedResult
            ]
          );
          if (textToAdd != undefined) {
            stringsToReturn.push(textToAdd);
          }
        }
      }
      return stringsToReturn;
    },
    generateBreaksText(setupResults) {
      let relevantContent = [];
      let breaksRoot = ReportResults.setup.categories.breaks;

      let hasPainSelector = !!setupResults.workpain ? "withPain" : "noPain";
      let breakActivityNode = breaksRoot.find(
        node => node.name === "breakActivity"
      ).values[setupResults.breakActivity];
      if (breakActivityNode !== undefined) {
        relevantContent.push(breakActivityNode[hasPainSelector]);
      }

      if (relevantContent.length === 0) {
        relevantContent.push(
          breaksRoot.find(node => node.name === "breaks").values[
            setupResults.breaks
          ]
        );
      }

      relevantContent.push(
        breaksRoot.find(node => node.name === "lunch").values[
          setupResults.lunch
        ]
      );

      return relevantContent.map(entry => this.$t(entry));
    },
    generateLaptopText() {
      let textToReturn = "";
      if (
        this.results.dockingstation === 2 &&
        this.results.separatemousekeyboard === 2
      ) {
        textToReturn += this.$t(
          ReportResults["setup"].categories["laptop"][2].text
        );
      } else if (
        this.results.dockingstation === 2 &&
        this.results.separatemousekeyboard === 1
      ) {
        textToReturn += this.$t(
          ReportResults["setup"].categories["laptop"][0].values[2]
        );
      } else if (
        this.results.dockingstation === 1 &&
        this.results.separatemousekeyboard === 2
      ) {
        textToReturn += this.$t(
          ReportResults["setup"].categories["laptop"][1].values[2]
        );
      }
      let addendum = this.$t(
        ReportResults["setup"].categories["laptop"][3].text
      );
      return [textToReturn, addendum];
    },
    generateStrainText() {
      let textToReturn = [];
      if (this.results.slopedsurface === 2) {
        textToReturn.push(
          this.$t(ReportResults["setup"].categories["strain"][0].values[2])
        );
      }
      if (this.results.headset === 2) {
        textToReturn.push(
          this.$t(ReportResults["setup"].categories["strain"][1].values[2])
        );
      }
      if (this.strainMultipleComputerScreens) {
        textToReturn.push(
          this.$t(ReportResults["setup"].categories["strain"][2].values[1])
        );
      } else if (this.strainMonitorOneMonitorAndScreen) {
        textToReturn.push(
          this.$t(ReportResults["setup"].categories["strain"][2].values[2])
        );
      }

      return textToReturn;
    },
    generateStandingDeskText() {
      return [
        this.$t(
          ReportResults["setup"].categories["standingdesk"][0].values[
            this.isStandingTimeGood()
          ],
          {
            0: this.percentageSpentStanding().toString()
          }
        )
      ];
    },
    generateTemperatureText() {
      let temperatureMeasurement = this.metricSystem ? "celcius" : "fahrenheit";
      let temperatureResult = this.results["temperature"];
      return [
        this.$t(
          ReportResults["setup"].categories["temperature"][0][
            temperatureMeasurement
          ][temperatureResult]
        )
      ];
    },
    generateChairText() {
      if (this.hasBeanBag) {
        return [this.$t("deskAssessmentReportDetailCards.setup.chair.beanBag")];
      }
      if (this.hasGymBall) {
        return [this.$t("deskAssessmentReportDetailCards.setup.chair.gymBall")];
      }
      return this.getCategoryText("chair", this.results);
    },
    percentageSpentStanding() {
      if (this.results.standingdesk && this.results.hours) {
        let percentage = Math.round(
          (this.results.hoursstandingdesk / this.results.hours) * 100
        );
        return percentage > 100 ? 100 : percentage;
      }
      return 0;
    },
    isStandingTimeGood() {
      let percentageStanding = this.percentageSpentStanding();
      if (percentageStanding < 25) {
        return "tooShort";
      } else if (percentageStanding <= 50) {
        return "ok";
      } else {
        return "tooLong";
      }
    },
    // if even number of cards (long -> short -> long -> short)
    // if odd (long -> short -> longest)
    sortSetupArray() {
      // sort content so longest text first
      this.setupContent.sort(function (a, b) {
        return a.text.length - b.text.length;
      });

      // create array alternating between longest and shortest text
      var alternatingArray = [];
      for (var i = 0; i < this.setupContent.length; i++) {
        alternatingArray.push(
          this.setupContent[
            i % 2 === 0 ? this.setupContent.length - 1 - i / 2 : (i - 1) / 2
          ]
        );
      }
      this.setupContent = alternatingArray;

      // if odd number flip alternating array so longest is last
      if (this.setupContent.length % 2 === 1) {
        this.setupContent.reverse();
      }
    },
    getLargeColumnSizeForSetupContent(index) {
      if (index % 2 === 0) {
        if (index === this.setupContent.length - 1) {
          return 12;
        }
        return 7;
      }
      return 5;
    }
  }
};
</script>
