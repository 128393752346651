var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.results.deskItems && _vm.results.deskItems.length > 0
                ? _c("visual-desk-planner-report-card", {
                    attrs: { results: _vm.results }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.workingFromHome
            ? _c(
                "v-col",
                { attrs: { lg: "5", cols: "12" } },
                [
                  _c("InformationCard", {
                    staticClass: "fill-height",
                    attrs: {
                      id: _vm.workingFromHomeCard.type,
                      sideHeader: false,
                      color: _vm.workingFromHomeCard.isGood
                        ? _vm.$vuetify.theme.currentTheme.success
                        : _vm.$vuetify.theme.currentTheme.warning,
                      title: _vm.workingFromHomeCard.title,
                      imageUrl: _vm.workingFromHomeCard.imageUrl,
                      text: _vm.workingFromHomeCard.text,
                      verticalAlignStart: false
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "pa-0 ma-0",
              attrs: { lg: _vm.workingFromHome ? 7 : 12, cols: "12" }
            },
            [
              _c(
                "v-row",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { lg: _vm.workingFromHome ? 12 : 6, cols: "12" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: _vm.hoursCard.type,
                          sideHeader: true,
                          title: _vm.hoursCard.title,
                          color: _vm.hoursCard.isGood
                            ? _vm.$vuetify.theme.currentTheme.success
                            : _vm.$vuetify.theme.currentTheme.warning,
                          imageUrl: _vm.hoursCard.imageUrl,
                          text: _vm.hoursCard.text
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { lg: _vm.workingFromHome ? 12 : 6, cols: "12" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: _vm.breaksCard.type,
                          sideHeader: true,
                          title: _vm.breaksCard.title,
                          color: _vm.breaksCard.isGood
                            ? _vm.$vuetify.theme.currentTheme.success
                            : _vm.$vuetify.theme.currentTheme.warning,
                          imageUrl: _vm.breaksCard.imageUrl,
                          text: _vm.breaksCard.text
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        _vm._l(_vm.setupContent, function(card, cardIndex) {
          return _c(
            "v-col",
            {
              key: cardIndex,
              staticClass: "d-flex",
              attrs: {
                cols: "12",
                lg: _vm.getLargeColumnSizeForSetupContent(cardIndex)
              }
            },
            [
              _c("InformationCard", {
                attrs: {
                  id: card.type,
                  sideHeader: cardIndex % 2 === 0,
                  title: card.title,
                  color: card.isGood
                    ? _vm.$vuetify.theme.currentTheme.success
                    : _vm.$vuetify.theme.currentTheme.warning,
                  imageUrl: card.imageUrl,
                  text: card.text
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }